/*
box shadow taken from shadow[12]:
https://material-ui.com/customization/default-theme/#default-theme
    box-shadow: "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)";
*/
.mobile:active {
    opacity: 0.5;
}

.desktop:hover:active {
    opacity: 0.5;
}

.fadeback {
    opacity: 1.0;
}
